import React from 'react'
import { Link } from 'gatsby'
import tw, { styled } from 'twin.macro'
import { PropTypes } from 'prop-types'
import useSiteMetadata from '../hooks/useSiteMetadata'

const StepsContainer = styled.div`
  ${tw`relative flex justify-between w-auto md:w-3/4 mx-2 md:mx-auto mt-4 mb-5 md:my-4`}
  max-width: ${({ theme }) => theme.screens.sm};

  ${({ theme }) => theme.mq('lg')} {
    max-width: ${({ theme }) => theme.screens.lg};
  }
`

const Step = styled.div`
  display: flex;
  align-items: center;
  flex: ${props => (props.lastItem ? `0 0 auto` : `1 0 auto`)};

  &:after {
    ${tw`bg-gray-200 mx-1`}
    content: '';
    height: 1px;
    width: 100%;
  }
`

const Nav = styled.nav`
  ${tw`flex justify-between`}

  .dashed {
    ${tw`absolute w-full border-b border-dashed border-gray-200 h-px`}
    top: 50%;
    z-index: -1;
  }
`

const NavLink = styled(Link)`
  ${tw`flex-shrink-0 h-8 w-8 md:h-8 md:w-8 lg:w-auto lg:px-4 flex items-center justify-center md:shadow-sm`}
  ${tw`text-sm text-gray-300 bg-white border border-gray-200 rounded-full`}
  ${tw`transition-colors duration-150 hocus:text-purple`}

  &.current-page{
    ${tw`bg-green text-white border-green md:shadow`}
  }

  &.disabled-link {
    pointer-events: none;
    cursor: not-allowed;
  }



  /* div {
    display:inline-block
  } */

  span.text {
    ${tw`hidden lg:inline-block ml-1`}
  }

  /* font-weight: ${props => props.fontWeight || normal}; */
`

const Icon = styled.span`
  ${tw`font-bold md:font-normal`}
  ${({ theme }) => theme.mq('lg')} {
    &:after {
      content: '.';
    }
  }
`

const Steps = ({ stepType }) => {
  const { stepLinks } = useSiteMetadata()

  const steps = stepLinks.filter(link => link.type === stepType)

  return (
    <StepsContainer>
      {steps.map((step, i) => (
        <Step key={i} lastItem={i == steps.length - 1}>
          <NavLink to={step.link} partiallyActive activeClassName="current-page" className="disabled-link">
            <Icon>{i + 1}</Icon>
            <span className="text"> {step.name}</span>
          </NavLink>
        </Step>
      ))}
    </StepsContainer>
  )
}

Steps.propTypes = {
  stepType: PropTypes.string.isRequired,
}

export default Steps
