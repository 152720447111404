/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import tw from 'twin.macro'

import Footer from './Footer'
import { FlexContainer } from './styles'

import Header from './Header'
import Steps from './Steps'
import Messenger from './Messenger'

const Layout = ({ hideSteps, stepType, children }) => (
  <FlexContainer>
    <Header showTitle />
    {!hideSteps && <Steps stepType={stepType} />}
    <main tw="flex-auto">{children}</main>
    <Footer />
  </FlexContainer>
)

Layout.defaultProps = {
  stepType: `public`,
}

Layout.propTypes = {
  hideSteps: PropTypes.bool,
  stepType: PropTypes.string,
  children: PropTypes.node.isRequired,
}

export default Layout
